a.linkBtn {
  text-decoration: none;
}

.linkBtn,
.linkBtn:hover {
  display: flex;
  justify-content: center;
  color: var(--danger);
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-light);
}
.disabledLink,
.disabledLink:hover {
  color: var(--neutral-dark-3);
  cursor: not-allowed;
}

@media screen and (min-width: 768px) {
  .wrapper {
    min-width: unset;
    width: 600px;
  }
}
