.datepicker, .datepicker:hover {
  border-radius: 50px !important;
  border: 1px solid var(--blue-light-5) !important;
  background-color: var(--blue-light-6) !important;
}

.datepicker input {
  border-radius: 50px !important;
  background-color: transparent !important;
}
