.layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.cardHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 15px 10px;
}

.cardHeader span {
  margin-left: 5px;
}

.cardBody {
  padding: 15px 20px 20px;
}

.cardBody p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}

.amountDetails {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.amountDetails div {
  width: auto;
}

.amountDetails .amount {
  font-size: 14px;
  font-weight: 600;
}

.amount span:last-child {
  color: #66b107;
}

.duration {
  font-size: 10px;
  color: #8b8b8b;
}

.modalWidth {
  max-width: 800px;
}

.bold {
  font-weight: 600;
  color: #000;
}

.actionButton {
  font-size: 14px;
  height: 32px;
}

.webForm {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  padding: 20px;
}

.alertContainer {
  position: fixed;
  bottom: 10px;
  right: 0;
  padding: 0 5%;
}

@media screen and (max-screen: 747px) {
  .actionButton {
    background-color: var(--neutral-dark-5);
  }
}
