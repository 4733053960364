.details {
  padding: 10px 12px;
  border-radius: 5px;
  background-color: var(--blue-light-6);
}

.summaryTitle {
  font-size: 20px;
  font-weight: bold;
  border-bottom: 2px solid var(--neutral-dark-3);
  padding-bottom: 5px;
}

.breakDown {
  display: flex;
  flex-direction: column;
}

.items {
  display: flex;
  justify-content: space-between;
}

.cards {
  display: flex;
}

.wrapper img {
  width: 100%;
  max-width: 1500px;
  height: 50px;
  border-radius: 5px;
}
.selected {
  border: 2px solid var(--blue-light-1);
}
