.wrapper {
  height: 100%;
  width: 100%;

  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  padding: 20px;
}

.wrapper header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.wrapper header .select_rselect__Y\+Mlm {
  margin-bottom: 0 !important;
}

.wrapper section {
  min-height: 200px;
}

.wrapper section.noData {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--neutral-dark-5);
  font-size: 24px;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.item {
  display: flex;
  gap: 20px;
  width: 100%;
}

.itemTitle {
  border-right: 1px solid var(--neutral-dark-5);
  min-width: 150px;
  padding: 10px 0;
  padding-right: 10px;
  text-align: right;
}

.valueBar {
  min-width: calc(70% - 50px);
  max-height: 20px;
  background-color: var(--neutral-dark-7);
  margin: 10px 0;
}

@media screen and (max-width: 767px) {
  .title,
  .itemTitle {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .itemTitle {
    min-width: 100px;
  }

  .valueBar {
    min-width: 65%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .title {
    font-size: 14px;
    font-weight: 600;
  }
}
