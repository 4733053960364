.wrapper {
  position: fixed;
  bottom: 50px;
  right: 50px;

  width: 300px;
  height: fit-content;
  z-index: 99;
}

.closeIcon {
  float: right;
  padding: 5px;
  width: fit-content;

  background-color: var(--neutral-light);
  border: 1px solid var(--neutral-dark-5);
  border-radius: 50%;
  color: var(--neutral-dark-3);
}

.badge {
  background-color: var(--red-primary);
  width: 10px;
  height: 10px;
  border-radius: 50%;

  display: inline-block;
  position: absolute;
  top: 1px;
  right: 1px;
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.hidden {
  display: none;
}
