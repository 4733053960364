.wrapper {
  background-color: var(--neutral-light);

  border: 1px solid var(--neutral-dark-5);
  border-radius: 8px;
  padding: 10px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  padding: 0 5px;
}

.itemWrapper {
  display: flex;
  column-gap: 10px;
  align-items: center;

  border-radius: 50px;
  margin-bottom: 10px;
  padding: 5px;

  background-color: var(--red-transparent-15);
  color: var(--red-primary);

  cursor: pointer;
}

.itemWrapper:last-child {
  margin-bottom: 0;
}

.itemCompleted {
  color: var(--green-primary);
  background-color: var(--green-transparent-15);
}
